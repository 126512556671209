<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="processTable2"
          title="단위공정 목록"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          :filtering="false"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <q-form ref="editForm">
          <c-card title="단위공정 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && this.parentProcess.processCd" label="단위공정" icon="add" @btnClicked="addChild" />
                <c-btn
                  v-if="editable"
                  :disabled="!saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  :required="true"
                  label="단위공정명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :required="true"
                  label="단위공정코드"
                  name="processCd"
                  v-model="data.processCd">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-checkbox
                  :editable="editable"
                  :comboItems="comboItems"
                  itemText="codeName"
                  itemValue="code"
                  label="상위공정정보 동일"
                  name="col1"
                  v-model="data.col1">
                </c-checkbox>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :required="true"
                  label="정렬순서"
                  name="orderNo"
                  v-model="data.orderNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-radio
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :counter="true"
                  :editable="editable"
                  label="단위공정 설명"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-upload
                  maxheight="max-height:130px;min-height:130px;"
                  :editable="editable"
                  label="단위공정 사진">
                </c-upload>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-upload
                  maxheight="max-height:130px;min-height:130px;"
                  :editable="editable"
                  label="공정설명서(공정흐름도)">
                </c-upload>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-table
                  ref="hazardTable"
                  title="유해위험정보"
                  tableId="hazardTable"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :filtering="false"
                  :columns="hazardGrid.columns"
                  :data="hazardGrid.data"
                  selection="multiple"
                  rowKey="code"
                  gridHeight="200px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <c-btn label="추가" icon="add" @btnClicked="addrow" />
                    <c-btn label="삭제" icon="remove" @btnClicked="removeRow" />
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-manage',
  props: {
    parentProcess: {
      type: Object
    },
  },
  data() {
    return {
      comboItems: [
        { code: 'Y', codeName: '상위공정정보 동일' },
      ],
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      hazardGrid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            label: '유해위험정보',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
        col1: []
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      lvl1Check: false,
      saveable: false,
      deleteable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.process.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedProcessCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    parentProcess() {
      this.getProcessList({ processCd: this.parentProcess.processCd })
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.insertUrl = transactionConfig.mdm.process.insert.url;
      this.updateUrl = transactionConfig.mdm.process.update.url;
      this.deleteUrl = transactionConfig.mdm.process.delete.url;
      if (this.parentProcess.processCd) {
        this.getProcessList({ processCd: this.parentProcess.processCd });
      }
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[1];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getProcessList(row) {
      this.getList(row);
      this.reset();
    },
    getList(row) {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        processLevelCd: '20',
        upProcessCd: row.processCd,
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedProcessCd = row.processCd;
      this.$http.url = this.$format(this.detailUrl, this.selectedProcessCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      },
      () => {
      });
    },
    addChild() {
      this.saveable = true;
      this.deleteable = false;
      this.lvl1Check = false;
      this.lvl2Check = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.data = {
        plantCd: this.parentProcess.plantCd,
        processCd: '',
        processName: '',
        upProcessCd: this.parentProcess.processCd,
        processLevelCd: '20',
        processDesc: '',
        managementDepts: this.parentProcess.managementDepts,
        orderNo: '',
        useFlag: 'Y',
        psmFlag: this.parentProcess.psmFlag,
        col1: []
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.data = {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
        col1: []
      };
    },
    addrow() {
    },
    removeRow() {
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.processCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.processCd);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            console.log(_result)
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getProcessList({ processCd: this.parentProcess.processCd })
      } else {
        this.getProcessList({ processCd: this.parentProcess.processCd })
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getProcessList({ processCd: this.parentProcess.processCd })
      this.selectedProcessCd = '';
    },
  }
};
</script>
